import React from "react"
import HelmetTitle from "../components/utils"
import { Container, Row, Col } from "reactstrap"
import billgistLogo from "../images/BillGist-final-logo.png"
import billgistPhoto from "../images/billgist-main-photo.png"
import Layout from "../components/Layout"
import "../scss/modules/_m-hero-billgist.scss"

const Billgist = () => {
  const title = "Billgist",
    tagline = `With billgist, users will be aware of their AWS cost by getting daily, weekly, or monthly 
      emails containing simple reports. Hence, billgist users will easily catch any sudden rise in their 
      AWS bills.`

  return (
    <Layout>
      <div className="billgist mb-6">
        <HelmetTitle title="Billgist" />
        <div className="billgist-banner pt-6 pb-6 mb-4 text-white text-justify">
          <Container fluid>
            <Row>
              <Col xl={{ size: 10, offset: 1 }}>
                <h1 className="mb-5 font-weight-bold">{title} </h1>
                <h3 className="mb-5 text-justify">{tagline}</h3>
              </Col>
            </Row>
          </Container>
        </div>

        <Container className="d-xl-none d-lg-none d-md-none" fluid>
          <Row>
            <Col
              xl={{ offset: 6 }}
              lg={{ offset: 5 }}
              md={{ offset: 6 }}
              sm={{ offset: 3 }}
              xs={{ offset: 2 }}
              className="billgist-logo-col"
            >
              <a
                href="https://billgist.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={billgistLogo}
                  alt="Billgist Logo"
                  className="img-responsive billgist-logo"
                />
              </a>
            </Col>
          </Row>
        </Container>

        <Container fluid>
          <Row>
            <Col
              xl={{ size: 4, offset: 1 }}
              md={{ size: 4, offset: 0 }}
              sm={{ size: 4, offset: 2 }}
              xs={{ offset: 2 }}
              className="billgist-img-col mt-4"
            >
              <img
                src={billgistPhoto}
                alt="Billgist Branding"
                className="img-responsive billgist-img"
              />
            </Col>

            <Col
              xl={{ size: 6, offset: 1 }}
              lg={{ size: 6, offset: 1 }}
              md={{ size: 6, offset: 2 }}
              sm={{ size: 8, offset: 2 }}
              xs={{ offset: 1 }}
              className="billgist-col"
            >
              <Row className="mt-4 mb-5 d-none d-sm-none d-md-block" noGutters>
                <a
                  href="https://billgist.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={billgistLogo}
                    alt="Billgist Logo"
                    className="img-responsive billgist-logo"
                  />
                </a>
              </Row>

              <h4 className="font-weight-bold aws-bill mt-2">
                Do not get surprised by your AWS bill
              </h4>
              <br />
              <p>
                <i
                  className="fa fa-check-circle mr-2 pt-1"
                  aria-hidden="true"
                />
                <span className="pl-4"> Get daily email reports.</span>
              </p>
              <p>
                <i
                  className="fa fa-check-circle mr-2 pt-1"
                  aria-hidden="true"
                />
                <span className="pl-4">Multiple email recipients.</span>
              </p>
              <p>
                <i
                  className="fa fa-check-circle mr-2 pt-1"
                  aria-hidden="true"
                />
                <span className="pl-4">Multiple AWS accounts.</span>
              </p>
              <p>
                <i
                  className="fa fa-check-circle mr-2 pt-1"
                  aria-hidden="true"
                />
                <span className="pl-4">
                  Notification on AWS global bill increase.
                </span>
              </p>
              <p>
                <i
                  className="fa fa-check-circle mr-2 pt-1"
                  aria-hidden="true"
                />
                <span className="pl-4">
                  Notification on AWS specific service cost increase.
                </span>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default Billgist
